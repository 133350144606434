<template>
  <DialogForm
    :flat="!isCreate"
    :loading="runningAction"
    :title="title"
    data-test-id="group"
    class="group-dialog"
  >
    <template #actions="{ loading }">
      <v-btn
        data-test-id="groupSaveBtn"
        :color="hasViolation ? 'error' : 'green'"
        :outlined="!isCreate && !hasChanged"
        :disabled="loading || disabled"
        @click="upsertGroup"
      >
        <ViolationAlert
          v-if="hasViolation"
          :violation="violation"
          :color="hasChanged || isCreate ? 'white' : 'error'"
          data-test-id="groupViolationAlert"
          alignment="left"
        />
        {{ isCreate ? "Create" : "Save" }}
      </v-btn>
      <v-btn
        v-if="!isCreate"
        color="red"
        outlined
        data-test-id="groupDeleteBtn"
        class="ml-2"
        :disabled="loading || disabled"
        @click="deleteGroup"
      >
        Delete
      </v-btn>
      <v-btn
        v-else
        text
        data-test-id="closeGroupDialogBtn"
        class="ml-2"
        :disabled="loading || disabled"
        @click="$emit('close')"
      >
        Cancel
      </v-btn>
    </template>

    <template #form="{ loading }">
      <v-form
        @submit.prevent
        ref="groupForm"
        class="group-form"
        data-test-id="groupForm"
      >
        <v-container fluid class="group-container">
          <v-row dense>
            <v-col>
              <v-text-field
                v-if="!isCreate"
                v-model="group.id"
                dense
                outlined
                disabled
                readonly
                label="ID*"
                :rules="[ruleSet.required]"
                data-test-id="groupId"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="group.name"
                dense
                outlined
                label="Name"
                hide-details="auto"
                data-test-id="groupName"
                :rules="[ruleSet.required, ruleSet.maxLength(255)]"
                :disabled="loading || disabled"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </DialogForm>
</template>

<script>
import DialogForm from "components/common/templates/DialogForm";
import ViolationAlert from "components/common/display-helpers/ViolationAlert";
import validationMixin from "mixins/field-rule-validation";
export default {
  mixins: [validationMixin],

  components: {
    DialogForm,
    ViolationAlert,
  },

  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      group: this.$cloneObject(this.value),
      savedGroup: this.value,
      hasChanged: false,
      violation: null,
      runningAction: false,
    };
  },

  watch: {
    value: {
      handler: function (value) {
        this.group = this.$cloneObject(value);
        this.savedGroup = value;
      },
      deep: true,
    },

    group: {
      handler: function (group) {
        this.hasChanged = !this.$isEqual(group, this.savedGroup);
      },
      deep: true,
    },

    savedGroup: {
      handler: function (savedGroup) {
        this.hasChanged = !this.$isEqual(this.group, savedGroup);
      },
      deep: true,
    },

    hasChanged(hasChanged) {
      if (this.isCreate) return;
      this.$set(this.$route.params, "unsavedChanges", hasChanged);
    },
  },

  methods: {
    async upsertGroup() {
      try {
        if (!this.validate()) return;
        this.runningAction = true;
        this.violation = null;
        let response;
        if (this.isCreate) {
          const body = {
            ...this.group,
          };

          response = await this.$store.$coreApi.coreUserApi.createGroup(
            this.selectedDomain,
            body,
            {
              returnErrors: true,
              successMsg:
                "Group <strong>" + this.group.name + "</strong> created",
            }
          );
        } else {
          response = await this.$store.$coreApi.coreUserApi.updateGroup(
            this.selectedDomain,
            this.group,
            {
              successMsg:
                "Group <strong>" + this.group.name + "</strong> updated",
              returnErrors: true,
            }
          );

          if (response?.ok) {
            this.savedGroup = this.$cloneObject(this.group);
          }
        }

        if (!response?.ok) {
          //Error handling
          try {
            const error = await response.json();
            if (error.violations) {
              this.violation = error;
              return;
            }
            this.violation = {
              message: error.message,
            };
          } catch (error) {
            this.violation = {
              message: "An unexpected error occured",
            };
          }
          return;
        }

        if (this.isCreate) {
          this.group = await response.json();
        }
        this.$emit("reload", this.group);
      } finally {
        this.runningAction = false;
      }
    },

    async deleteGroup() {
      if (
        await this.$confirm(
          "Delete group?",
          "Are you sure you want to delete group <strong>" +
            this.value.name +
            "</strong>? This cannot be undone."
        )
      ) {
        this.runningAction = true;
        try {
          const response = await this.$store.$coreApi.coreUserApi.deleteGroup(
            this.selectedDomain,
            this.group.id,
            {
              successMsg:
                "Group <strong>" + this.value.name + "</strong> deleted",
            }
          );
          if (!response?.ok) return;
          this.$emit("deleted");
        } finally {
          this.runningAction = false;
        }
      }
    },

    validate() {
      const form = this.$refs.groupForm;
      if (!this.$validateVForm(form)) {
        this.violation = {
          message: "At least one input is invalid, please check",
        };
        return false;
      }
      this.violation = null;
      return true;
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    isCreate() {
      return !this.value?.id;
    },

    title() {
      return !this.isCreate ? "Group Information" : "Create Group";
    },

    hasViolation() {
      return !!this.violation;
    },
  },
};
</script>

<style scoped>
.dialog-form.group-dialog::v-deep .group-container {
  display: flex;
  flex-direction: column;
  padding: 0;
}
</style>