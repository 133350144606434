import { render, staticRenderFns } from "./GroupDetail.vue?vue&type=template&id=67ad3bc8&scoped=true&"
import script from "./GroupDetail.vue?vue&type=script&lang=js&"
export * from "./GroupDetail.vue?vue&type=script&lang=js&"
import style0 from "./GroupDetail.vue?vue&type=style&index=0&id=67ad3bc8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67ad3bc8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VForm})
