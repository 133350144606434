var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MasterDetail',{attrs:{"data-test-id":"groupPage","detail-open":!!_vm.selectedGroup,"loading":_vm.runningAction},on:{"table:resize":function($event){_vm.tableWidth = $event}},scopedSlots:_vm._u([{key:"toolbar",fn:function(ref){
var loading = ref.loading;
return [_c('Toolbar',{staticClass:"group-table-toolbar",attrs:{"search-props":{
        disableFullTextSearch: true,
        possibleFilters: _vm.possibleFilters,
        uniqueFilters: true,
      }},on:{"update-filters":function (updated) { return (_vm.filters = updated); }},scopedSlots:_vm._u([{key:"extended",fn:function(){return [_c('div',{staticClass:"extended-toolbar"},[_c('v-select',{staticClass:"group-limit-select",attrs:{"dense":"","hide-details":"","items":_vm.pageLimits,"disabled":loading,"data-test-id":"pageLimitSelector"},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}}),_vm._v(" rows "),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('PaginationComponent',{attrs:{"disabled":loading,"disable-next-btn":!_vm.hasMorePages,"hide-page-input":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","data-test-id":"newGroup","title":"Create a new group","fab":_vm.showMinified,"small":_vm.showMinified,"disabled":loading},on:{"click":function($event){_vm.createGroup = true}}},[(_vm.showMinified)?_c('v-icon',[_vm._v("mdi-plus")]):_c('div',[_vm._v("New Group")])],1)],1),_c('v-dialog',{attrs:{"persistent":"","data-test-id":"createGroupDialog","max-width":"500px"},model:{value:(_vm.createGroup),callback:function ($$v) {_vm.createGroup=$$v},expression:"createGroup"}},[(_vm.createGroup)?_c('Group',{on:{"reload":_vm.closeDialog,"close":function($event){return _vm.closeDialog()}}}):_vm._e()],1)]},proxy:true}],null,true),model:{value:(_vm.filterQuery),callback:function ($$v) {_vm.filterQuery=$$v},expression:"filterQuery"}})]}},{key:"table",fn:function(ref){
      var loading = ref.loading;
return [_c('v-data-table',{staticClass:"group-table",attrs:{"dense":"","disable-pagination":"","hide-default-footer":"","fixed-header":"","single-select":"","multi-sort":"","data-test-id":"groupTable","items":_vm.groups,"headers":_vm.headers,"loading":loading,"height":_vm.tableHeight,"item-class":_vm.getItemClass},on:{"click:row":_vm.openGroupDetail}})]}},{key:"detail",fn:function(){return [(!!_vm.selectedGroup)?_c('GroupDetail',{key:_vm.selectedGroup.id,style:({
        height: _vm.detailHeight + 'px',
      }),attrs:{"preview-data":_vm.selectedGroup},on:{"close":_vm.closeDetail,"reload":_vm.reloadGroups}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }