<template>
  <v-combobox
    v-model="user"
    outlined
    dense
    hide-details="auto"
    placeholder="Select User..."
    background-color="white"
    ref="userSelect"
    no-filter
    return-object
    item-text="username"
    :search-input.sync="search"
    :disabled="disabled"
    :loading="runningAction"
    :items="users"
    :rules="rules"
    data-test-id="userSelectCombobox"
  >
    <template #no-data>
      <div class="d-flex justify-center">
        <v-subheader>{{ noDataText }}</v-subheader>
      </div>
    </template>

    <template #prepend-item>
      <div class="d-flex flex-row flex-grow-1 align-center px-2 py-1">
        <div class="text-caption">
          <strong>Matching users for username (first 20 shown)</strong>
        </div>
      </div>
      <v-divider class="my-2" />
    </template>

    <template #item="{ item, on, attrs }">
      <v-list-item v-on="on" v-bind="attrs" dense>
        <v-list-item-title :data-test-id="'user_' + item.username">{{
          item.username
        }}</v-list-item-title>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    rules: {
      type: Array,
      required: false,
      validator: (rules) => rules.every((rule) => typeof rule === "function"),
    },
  },

  data() {
    return {
      users: [],
      dummyModel: this.value,
      runningAction: false,
      showHelperDialog: false,
      timeout: 0,
      user: null,
      search: null,
    };
  },

  mounted() {
    this.loadUsers();
  },

  watch: {
    search() {
      window.clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.loadUsers();
      }, 300);
    },

    user(user) {
      this.$emit("input", user);
    },
  },

  methods: {
    async loadUsers() {
      try {
        this.runningAction = true;
        const username = this.search;
        const body = {
          offset: 0,
          limit: 20,
          username,
        };
        const response = await this.$store.$coreApi.coreUserApi.searchUsers(
          this.selectedDomain,
          body
        );
        if (!response?.ok) return;
        const responseBody = await response.json();
        const users = responseBody?.users ?? [];
        this.users = users;
      } finally {
        this.runningAction = false;
      }
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    noDataText() {
      return "No users found for this name";
    },
  },
};
</script>
