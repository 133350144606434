export default {
    methods: {
        getUserRoute(domain, userId) {
            return {
                name: "userDetail",
                params: {
                    user: userId,
                    domain,
                    keepParams: true,
                },
            };
        },

        getUserLink(domain, userId) {
            const route = this.getUserRoute(domain, userId);
            return this.$router.resolve(route).href;
        },

        async openUserDetail(domain, userId) {
            const route = this.getUserRoute(domain, userId);
            return await this.$router.push(route);
        },
    }
}