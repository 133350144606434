<template>
  <DataTable
    title="Ancestors"
    class="group-ancestor-overview"
    :loading="runningAction"
    :items="ancestors"
    :headers="groupAncestorHeaders"
  >
    <template #actions="{ loading }">
      <PaginationComponent
        v-model="currentPage"
        :disabled="loading"
        :disable-next-btn="!hasMorePages"
        hide-page-input
      />
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        data-test-id="addAncestorBtn"
        :disabled="disabled || loading"
        @click="addGroupAncestor"
      >
        Add ancestor
      </v-btn>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.name="{ item }">
      <a
        :href="getGroupLink(item.domainId, item.id)"
        :data-test-id="'groupAncestorName_' + item.name"
        @click.stop.prevent="openGroupDetail(item.domainId, item.id)"
      >
        {{ item.name }}
      </a>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.actions="{ item }">
      <v-btn
        small
        icon
        color="red"
        :disabled="disabled || runningAction"
        :data-test-id="'groupAncestorDeleteBtn_' + item.name"
        @click="deleteGroupAncestor(item)"
      >
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </template>
    <!-- eslint-disable-next-line -->
    <template #body.append>
      <tr v-for="(ancestor, index) in newAncestors" :key="index">
        <td>
          <GroupSelector
            :group-id="groupId"
            @input="updateAncestor(ancestor, $event)"
          />
        </td>
        <td>
          <v-btn
            small
            icon
            color="green"
            :disabled="disabled || runningAction || !ancestor.id"
            :data-test-id="'groupAncestorSaveBtn_' + index"
            @click="createGroupAncestor(ancestor, index)"
          >
            <v-icon small>mdi-content-save</v-icon>
          </v-btn>
          <v-btn
            small
            icon
            :disabled="disabled || runningAction"
            :data-test-id="'groupAncestorCancelBtn_' + index"
            @click="removeGroupAncestor(index)"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
  </DataTable>
</template>

<script>
import DataTable from "components/common/templates/DataTable";
import PaginationComponent from "components/PaginationComponent";
import GroupSelector from "../GroupSelector";
import groupMixin from "mixins/group-mixin";
export default {
  mixins: [groupMixin],
  components: {
    DataTable,
    PaginationComponent,
    GroupSelector,
  },

  props: {
    groupId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      ancestors: [],
      runningAction: false,
      limit: 10,
      currentPage: 1,
      hasMorePages: false,
      newAncestors: [],
    };
  },

  mounted() {
    this.loadAncestorGroups();
  },

  watch: {
    ancestors(ancestors) {
      this.$emit("update", ancestors);
    },

    currentPage() {
      this.loadAncestorGroups();
    },
  },

  methods: {
    async loadAncestorGroups() {
      try {
        this.runningAction = true;
        const offset =
          this.limit * (this.currentPage > 0 ? this.currentPage - 1 : 0);
        const response =
          await this.$store.$coreApi.coreUserApi.getGroupAncestors(
            this.selectedDomain,
            this.groupId,
            this.limit,
            offset
          );
        const ancestors = response?.groups ?? [];
        this.ancestors = ancestors;
        this.hasMorePages = response?.hasMore ?? false;
      } finally {
        this.runningAction = false;
      }
    },

    addGroupAncestor() {
      this.newAncestors.push({
        id: null,
        isNew: true,
      });
    },

    removeGroupAncestor(index) {
      this.newAncestors.splice(index, 1);
    },

    updateAncestor(ancestor, { name, id }) {
      this.$set(ancestor, "id", id);
      this.$set(ancestor, "name", name);
    },

    async createGroupAncestor({ id, name }, index) {
      try {
        this.runningAction = true;
        const successMsg =
          "Added group <strong>" + name + "</strong> to ancestors";
        const response =
          await this.$store.$coreApi.coreUserApi.createGroupAncestor(
            this.selectedDomain,
            this.groupId,
            id,
            {
              successMsg,
            }
          );
        if (!response?.ok) return;
        this.removeGroupAncestor(index);
        await this.loadAncestorGroups();
      } finally {
        this.runningAction = false;
      }
    },

    async deleteGroupAncestor({ id, name }) {
      try {
        const confirmed = await this.$confirm(
          "Remove ancestor?",
          "Are you sure you want to remove group <strong>" +
            name +
            "</strong> from ancestors?"
        );

        if (!confirmed) return;
        this.runningAction = true;
        const successMsg =
          "Removed group <strong>" + name + "</strong> from ancestors";
        const response =
          await this.$store.$coreApi.coreUserApi.deleteGroupAncestor(
            this.selectedDomain,
            this.groupId,
            id,
            {
              successMsg,
            }
          );
        if (!response?.ok) return;
        await this.loadAncestorGroups();
      } finally {
        this.runningAction = false;
      }
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    groupAncestorHeaders() {
      return [
        { text: "Name", value: "name", sortable: false },
        { text: "", value: "actions", sortable: false, width: "128px" },
      ];
    },
  },
};
</script>

<style scoped>
.group-ancestor-overview {
  padding: 12px;
}
</style>