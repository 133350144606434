var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-member-overview"},[_c('DataTable',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.members},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var loading = ref.loading;
return [_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","data-test-id":"addMemberBtn","disabled":_vm.disabled || loading},on:{"click":function($event){_vm.addMember = true}}},[_vm._v(" Add Member ")])]}},{key:"item.username",fn:function(ref){
var item = ref.item;
var loading = ref.loading;
return [_c('div',{staticClass:"d-flex flex-grow-1 align-center justify-start"},[_c('div',[_c('a',{attrs:{"href":_vm.getUserLink(_vm.selectedDomain, item.id),"data-test-id":'groupMemberName_' + item.username},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openUserDetail(_vm.selectedDomain, item.id)}}},[_vm._v(" "+_vm._s(item.username)+" ")])]),(_vm.username === item.username)?_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":"","outlined":"","color":"psgreen"}},[_vm._v(" YOU ")]):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var loading = ref.loading;
return [_c('v-btn',{attrs:{"small":"","icon":"","color":"red","disabled":_vm.disabled || loading,"data-test-id":'removeMemberBtn_' + item.username},on:{"click":function($event){return _vm.removeMember(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.addMember),callback:function ($$v) {_vm.addMember=$$v},expression:"addMember"}},[(_vm.addMember)?_c('Member',{attrs:{"group-id":_vm.groupId},on:{"close":_vm.closeDialog}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }