<template>
  <MasterDetail
    data-test-id="groupPage"
    :detail-open="!!selectedGroup"
    :loading="runningAction"
    @table:resize="tableWidth = $event"
  >
    <template #toolbar="{ loading }">
      <Toolbar
        v-model="filterQuery"
        class="group-table-toolbar"
        :search-props="{
          disableFullTextSearch: true,
          possibleFilters,
          uniqueFilters: true,
        }"
        @update-filters="(updated) => (filters = updated)"
      >
        <template #extended>
          <div class="extended-toolbar">
            <v-select
              class="group-limit-select"
              v-model="limit"
              dense
              hide-details
              :items="pageLimits"
              :disabled="loading"
              data-test-id="pageLimitSelector"
            />
            rows
            <v-divider class="mx-4" vertical></v-divider>

            <!-- CUSTOM PAGINATION COMPONENT -->
            <PaginationComponent
              v-model="currentPage"
              :disabled="loading"
              :disable-next-btn="!hasMorePages"
              hide-page-input
            />
            <v-divider class="mx-4" vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="ml-3"
              data-test-id="newGroup"
              title="Create a new group"
              :fab="showMinified"
              :small="showMinified"
              :disabled="loading"
              @click="createGroup = true"
            >
              <v-icon v-if="showMinified">mdi-plus</v-icon>
              <div v-else>New Group</div>
            </v-btn>
          </div>

          <v-dialog
            v-model="createGroup"
            persistent
            data-test-id="createGroupDialog"
            max-width="500px"
          >
            <Group
              v-if="createGroup"
              @reload="closeDialog"
              @close="closeDialog()"
            />
          </v-dialog>
        </template>
      </Toolbar>
    </template>

    <template #table="{ loading }">
      <v-data-table
        dense
        disable-pagination
        hide-default-footer
        fixed-header
        single-select
        multi-sort
        class="group-table"
        data-test-id="groupTable"
        :items="groups"
        :headers="headers"
        :loading="loading"
        :height="tableHeight"
        :item-class="getItemClass"
        @click:row="openGroupDetail"
      />
    </template>

    <template #detail>
      <GroupDetail
        v-if="!!selectedGroup"
        :preview-data="selectedGroup"
        :key="selectedGroup.id"
        :style="{
          height: detailHeight + 'px',
        }"
        @close="closeDetail"
        @reload="reloadGroups"
      />
    </template>
  </MasterDetail>
</template>

<script>
import MasterDetail from "components/common/templates/MasterDetail";
import Toolbar from "components/common/templates/Toolbar";
import PaginationComponent from "components/PaginationComponent";
import Group from "./Group";
import GroupDetail from "./GroupDetail";
import mainOverviewMixin from "mixins/main-overview-mixin";
export default {
  mixins: [mainOverviewMixin],
  components: {
    MasterDetail,
    Toolbar,
    PaginationComponent,
    Group,
    GroupDetail,
  },

  data() {
    return {
      groups: [],
      selectedGroup: null,
      tableWidth: this.getPageWidth(),
      runningAction: false,
      filters: {},
      filterQuery: null,
      limit: 20,
      currentPage: 1,
      hasMorePages: false,
      createGroup: false,
    };
  },

  created() {
    this.init();
  },

  watch: {
    "$route.params": async function (newParams, oldParams) {
      if (this.$route.matched.some((route) => route.name === "groups")) {
        const hasChanged = !this.$isEqual(newParams, oldParams);
        const domainChange = !this.$isEqual(
          newParams?.domain,
          oldParams?.domain
        );
        //make sure the group detail gets opened, when the group is
        //visited through a link on the current domain (e.g. link in group detail)
        if (hasChanged && !domainChange) {
          this.init(true);
        }
      }
    },

    async currentPage(page) {
      //reload customers if the current page changes
      if (page < 1) page = 1;

      if (page > 0) {
        await this.$router.push({
          query: Object.assign({}, this.$route.query, {
            page,
          }),
        });

        await this.loadGroups();
      }
    },

    filterQuery: {
      handler: async function (filterQuery) {
        let query = Object.assign({}, this.$route.query);
        const oldFilterQuery = query.filter;
        //remove filter query if the query is faulty
        if (Object.keys(filterQuery).length > 0) {
          let copy = this.$cloneObject(filterQuery);
          if (this.$isPlainObject(copy)) {
            copy = JSON.stringify(copy);
          }
          query.filter = this.$urlEncode(copy);
        } else {
          delete query.filter;
        }
        await this.$router.push({ ...this.$route, query });
        if (oldFilterQuery !== query.filter) this.loadGroups();
      },
      deep: true,
    },

    async limit(limit) {
      await this.$router.push({
        ...this.$route,
        query: Object.assign({}, this.$route.query, { limit }),
      });

      if (this.currentPage > 1) this.currentPage = 1;
      else this.loadGroups();
    },
  },

  methods: {
    async init(preventReload = false) {
      //initialize the component with the route parameters
      const namedRoute = this.$route.name;
      const routeQuery = this.$route.query;

      //get the query parameters and update the according variables
      let page = routeQuery.page;
      let limit = routeQuery.limit;
      let filter = routeQuery.filter;

      let pageChange = false;

      if (page) {
        page = parseInt(page, 10);
        if (Number.isNaN(page)) page = 1;
        pageChange = page !== this.currentPage;
        this.currentPage = page;
      }

      if (limit) {
        limit = parseInt(limit, 10);
        if (Number.isNaN(limit) || !this.pageLimits.includes(limit))
          limit = this.pageLimits[0];
        this.limit = limit;
      }

      if (filter) {
        try {
          filter = this.$urlDecode(filter);
          this.filterQuery = JSON.parse(filter);
        } catch (e) {
          this.filterQuery = null;
          console.warn(e);
        }
      }

      let query = {};
      if (this.currentPage) {
        query.page = this.currentPage;
      }
      if (this.limit) {
        query.limit = this.limit;
      }
      if (this.filterQuery) {
        query.filter = this.$urlEncode(JSON.stringify(this.filterQuery));
      }

      //update the URL query
      await this.$router.replace({ ...this.$route, query });

      if (
        (!preventReload && namedRoute === "groups") ||
        (namedRoute === "groupDetail" && (pageChange || !this.selectedGroup))
      ) {
        //load customers either if the table page has changed or the customer is requested directly via URL
        //if a filter is set as query parameter, do not load it here, because the customers are already reloaded when changing the filter
        await this.loadGroups();
      }

      if (namedRoute === "groupDetail") {
        //show customer detail of requested customer
        const groupId = this.$route.params.group;
        if (!groupId) {
          this.closeDetail();
        } else if (!this.selectedGroup || groupId !== this.selectedGroup.id) {
          this.selectedGroup = await this.$store.$coreApi.coreUserApi.getGroup(
            this.selectedDomain,
            groupId
          );
          if (!this.selectedGroup) this.closeDetail();
        }
      }
    },

    async loadGroups() {
      try {
        this.runningAction = true;
        const offset =
          this.limit * (this.currentPage > 0 ? this.currentPage - 1 : 0);
        const nameFilter = Object.values(this.filters).find(
          ({ property }) => property === "name"
        );
        const name = nameFilter?.value;
        const body = {
          offset,
          limit: this.limit,
          name,
        };
        const response = await this.$store.$coreApi.coreUserApi.searchGroups(
          this.selectedDomain,
          body
        );
        if (!response?.ok) return;
        const responseBody = await response.json();
        const groups = responseBody?.groups ?? [];
        this.groups = groups;
        this.hasMorePages = responseBody.hasMore;
      } finally {
        this.runningAction = false;
      }
    },

    async reloadGroups(group) {
      this.selectedGroup = this.$cloneObject(group);
      await this.loadGroups();
    },

    async closeDialog(group) {
      this.createGroup = false;
      if (group) {
        await this.closeDetail(true);
        await this.openGroupDetail(group);
      }
    },

    async openGroupDetail(group) {
      if (this.selectedGroup?.id !== group.id) {
        await this.$router.push({
          name: "groupDetail",
          params: { group: group.id },
          query: this.$route.query,
        });
        this.selectedGroup = group;
      }
    },

    async closeDetail(reload = false) {
      await this.$router.push({
        name: "groups",
        query: this.$route.query,
      });
      this.selectedGroup = null;
      if (reload) this.loadGroups();
    },

    getItemClass(item) {
      let itemClass = ["group-item", item.name];
      if (item.id === this.selectedGroup?.id) itemClass.push("selected");
      return itemClass.join(" ");
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    headers() {
      return [{ text: "Name", value: "name", sortable: false }];
    },

    possibleFilters() {
      return [{ text: "Name", property: "name", type: "text" }];
    },

    pageLimits() {
      return [20, 100, 200];
    },

    showMinified() {
      return (
        (this.selectedGroup && this.tableWidth <= 900) ||
        this.getPageWidth() <= 900
      );
    },
  },
};
</script>

<style scoped>
.group-table-toolbar .extended-toolbar {
  display: flex;
  height: 70px;
  align-items: center;
  padding: 0 12px 8px 12px;
}

.group-table-toolbar .extended-toolbar .group-limit-select {
  max-width: 70px;
  margin-right: 4px;
}
</style>