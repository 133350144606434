<template>
  <DialogForm
    class="new-member-form"
    data-test-id="member"
    title="New Member"
    save-btn-label="Add"
    close-btn-label="Cancel"
    :loading="runningAction"
    @close="$emit('close', false)"
    @save="addMember"
  >
    <template #form="{ loading }">
      <v-form @submit.prevent ref="memberForm">
        <v-container fluid class="pa-0">
          <v-row>
            <v-col>
              <UserSelector
                v-model="member"
                :disabled="loading || disabled"
                :rules="[ruleSet.required]"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </DialogForm>
</template>


<script>
import DialogForm from "components/common/templates/DialogForm";
import UserSelector from "components/user-management/user/UserSelector";
import validationMixin from "mixins/field-rule-validation";

export default {
  mixins: [validationMixin],
  components: {
    UserSelector,
    DialogForm,
  },

  props: {
    groupId: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      runningAction: false,
      member: {},
    };
  },

  methods: {
    async addMember() {
      try {
        this.runningAction = true;
        const form = this.$refs.memberForm;
        if (this.$validateVForm(form)) {
          const username = this.member?.username;
          const successMsg =
            `Added user <strong>` + username + `</strong> to group`;
          const response =
            await this.$store.$coreApi.coreUserApi.addUserToGroup(
              this.selectedDomain,
              this.member.id,
              this.groupId,
              { successMsg }
            );

          if (response.ok) {
            this.$emit("close", true);
          }
        }
      } finally {
        this.runningAction = false;
      }
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },
  },
};
</script>

<style scoped>
</style>