<template>
  <DetailView
    data-test-id="groupDetail"
    :title="title"
    :tabs="tabs"
    :loading="runningAction"
    @close="$emit('close')"
  >
    <template #actions>
      <div></div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.overview="{ loading }">
      <v-form
        @submit.prevent
        ref="groupForm"
        class="group-form"
        data-test-id="groupForm"
      >
        <!-- COMMON ATTRIBUTES -->
        <Group
          v-model="group"
          :disabled="runningAction"
          @reload="$emit('reload', $event)"
          @deleted="$emit('close', true)"
        />
        <GroupAncestorOverview
          :group-id="group.id"
          :disabled="runningAction"
          @update="updateAncestors"
        />
      </v-form>
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.permissions="{ loading }">
      <GroupPermissionOverview
        :key="ancestorUpdate"
        :group-id="group.id"
        :disabled="runningAction"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.members="{ loading }">
      <GroupMembersOverview :group-id="group.id" :disabled="runningAction" />
    </template>
  </DetailView>
</template>

<script>
import Group from "./Group";
import DetailView from "components/common/templates/DetailView";
import GroupAncestorOverview from "./ancestor/GroupAncestorOverview";
import GroupPermissionOverview from "./permission/GroupPermissionOverview";
import GroupMembersOverview from "./member/GroupMemberOverview";
export default {
  components: {
    Group,
    DetailView,
    GroupAncestorOverview,
    GroupPermissionOverview,
    GroupMembersOverview,
  },

  props: {
    previewData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      group: this.$cloneObject(this.previewData),
      savedGroup: this.previewData,
      hasChanged: false,
      violation: null,
      runningAction: false,
      ancestorUpdate: 0,
    };
  },

  mounted() {
    this.loadGroup();
  },

  methods: {
    async loadGroup() {
      try {
        this.runningAction = true;
        const group = await this.$store.$coreApi.coreUserApi.getGroup(
          this.selectedDomain,
          this.previewData.id
        );
        this.group = group;
        this.savedGroup = this.$cloneObject(group);
      } finally {
        this.runningAction = false;
      }
    },

    updateAncestors() {
      this.ancestorUpdate++;
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    tabs() {
      return [
        { text: "Overview", value: "overview" },
        { text: "Permissions", value: "permissions" },
        { text: "Members", value: "members" },
      ];
    },

    title() {
      return this.previewData?.name ?? this.savedGroup?.name;
    },
  },
};
</script>

<style scoped>
.group-detail .group-detail-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>