<template>
  <div class="group-member-overview">
    <DataTable dense :headers="headers" :items="members">
      <template #actions="{ loading }">
        <v-spacer />
        <v-btn
          color="primary"
          data-test-id="addMemberBtn"
          :disabled="disabled || loading"
          @click="addMember = true"
        >
          Add Member
        </v-btn>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.username="{ item, loading }">
        <div class="d-flex flex-grow-1 align-center justify-start">
          <div>
            <a
              :href="getUserLink(selectedDomain, item.id)"
              :data-test-id="'groupMemberName_' + item.username"
              @click.stop.prevent="openUserDetail(selectedDomain, item.id)"
            >
              {{ item.username }}
            </a>
          </div>
          <v-chip
            v-if="username === item.username"
            x-small
            outlined
            class="ml-2"
            color="psgreen"
          >
            YOU
          </v-chip>
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.actions="{ item, loading }">
        <v-btn
          small
          icon
          color="red"
          :disabled="disabled || loading"
          :data-test-id="'removeMemberBtn_' + item.username"
          @click="removeMember(item)"
        >
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
    </DataTable>
    <v-dialog v-model="addMember" persistent max-width="500">
      <Member v-if="addMember" :group-id="groupId" @close="closeDialog" />
    </v-dialog>
  </div>
</template>
<script>
import DataTable from "components/common/templates/DataTable";
import Member from "./Member";
import userMixin from "mixins/user-mixin.js";
export default {
  mixins: [userMixin],
  components: {
    Member,
    DataTable,
  },

  props: {
    groupId: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      members: [],
      runningAction: true,
      addMember: false,
    };
  },

  mounted() {
    this.loadMembers();
  },

  methods: {
    async loadMembers() {
      try {
        this.runningAction = true;
        const offset = 0;
        const response = await this.$store.$coreApi.coreUserApi.getGroupMembers(
          this.selectedDomain,
          this.groupId,
          this.limit,
          offset
        );

        this.members = response?.users ?? [];
      } finally {
        this.runningAction = false;
      }
    },

    async removeMember({ id, username }) {
      try {
        const confirmed = await this.$confirm(
          "Remove member?",
          "Are you sure you want to remove user <strong>" +
            username +
            "</strong> from group members?"
        );

        if (!confirmed) return;

        this.runningAction = true;
        const successMsg =
          `Removed user <strong>` + username + `</strong> from group`;
        const response =
          await this.$store.$coreApi.coreUserApi.removeUserFromGroup(
            this.selectedDomain,
            id,
            this.groupId,
            {
              successMsg,
            }
          );

        if (response?.ok) {
          await this.loadMembers();
        }
      } finally {
        this.runningAction = false;
      }
    },

    closeDialog(reload) {
      this.addMember = false;
      if (reload) this.loadMembers();
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    limit() {
      return Math.pow(2, 31) - 1;
    },

    headers() {
      return [
        { text: "Username", value: "username" },
        { text: "", value: "actions", sortable: false, width: "128px" },
      ];
    },

    username() {
      return this.$keycloak?.tokenParsed?.preferred_username;
    },
  },
};
</script>
<style scoped>
.group-member-overview {
  display: flex;
  flex-grow: 1;
  padding: 12px;
  flex-grow: 1;
  align-items: flex-start;
}
</style>

